/* Estilos Globales */
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  color: #666666;
}

/* Títulos generales */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #79ab92;
  margin: 0 0 1% 0;
  line-height: 1.2;
}

/* Contenedor principal */
.container {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px; /* Limita el ancho en pantallas grandes */
  padding: 0 1%;
}

.section {
  scroll-margin-top: 60px;
}

/* Sección centrada: título */
.section-centered .section-title {
  text-align: center;
  font-size: 150%; /* Cambiado a porcentaje */
  color: #79ab92;
  margin-bottom: 2%;
  margin-top: 5%;
}

/* Sección centrada: contenido */
.section-centered .section-content {
  max-width: 100%;
  margin: 0 auto;
}

/* Sección centrada: párrafos */
.section-centered .section-content p {
  text-align: left;
  font-style: italic;
  font-size: 100%; /* Cambiado a porcentaje */
  color: #666666;
  margin-bottom: 5%;
}

/* Imagen en secciones */
.section-image {
  width: 50%;
  height: auto;
  margin-left: 2%;
  filter: grayscale(100%);
  border-radius: 0.5%;
}

/* Encabezado principal */
.App-header {
  background-image: url("/src/Assets/header.png");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 6% 0%;
  text-align: center;
  height: 40%; /* Cambiado a porcentaje */
  position: relative;
  display: flex;
  margin-top: -55px;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

/* Overlay en el encabezado */
.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.App-header h1,
.App-header h2,
.App-header p,
.navbar {
  display: flex;
  justify-content: center;
  z-index: 99;
  position: sticky; /* Cambiado a sticky */
  top: 0; /* Se adhiere a la parte superior */
  transition: background-color 0.3s; /* Transición suave para el fondo */
  padding: 10px 0; /* Espaciado superior e inferior */
}


.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semi-transparente solo al scrollear */
}

.navbar-mobile {
  display: flex;
  z-index: 99;
  position: sticky;
  top: 0;
  padding: 10px 0 10px 10px;
  background-color: #ffffff;
  transition: background-color 0.3s; /* Transición suave para el fondo */
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
}

.mobile-menu-button {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #79AB92;
}

.nav-links-mobile {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  padding-left: 17px;
  gap: 1rem;
  font-size: 15px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease; /* Transición para apertura y cierre */
}

/* Añadimos la clase "open" para la animación de apertura */
.nav-links-mobile.open {
  max-height: 300px; /* Ajusta la altura máxima según la cantidad de enlaces */
  padding-bottom: 10px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
  padding-top: 10px;
}


.navbar a,
.logo {
  filter: none;
  opacity: 1;
}

.navbar a {
  margin: 0 1.5%;
  color: white;
  text-decoration: none;
  font-size: 100%;
}

.navbar a:hover {
  text-decoration: underline;
}

/* Estilos Responsive */
@media (max-width: 1200px) {
  .container {
    width: 90%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
  }

  .App-header {
    height: 45%;
    padding: 10% 0%; /* Ajuste de altura en pantallas medianas */
  }

  .section-image {
    width: 60%; /* Imagen más grande en pantallas medianas */
  }
}

@media (max-width: 768px) {
  .container {
    width: 95%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
  }

  .App-header {
    padding: 10% 5%;
    margin-top: 0px;
    height: 50%; /* Ajuste de altura en pantallas pequeñas */
  }

  .section-centered .section-title {
    font-size: 100%;
  }

  .section-image {
    width: 70%; /* Imagen más grande en pantallas pequeñas */
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    padding: 0 1.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
  }

  .App-header {
    padding: 15% 5%;
    height: auto; /* Dejar que el contenido defina la altura */
  }

    .navbar {
      flex-direction: column;
      align-items: center;
    }
  
    .navbar a {
      margin: 1rem 0;
      font-size: 100%;
    }

  .section-centered .section-title {
    font-size: 100%;
  }

  .section-image {
    width: 100%; /* Imagen ocupa todo el ancho en pantallas pequeñas */
  }
}

/* Contenedor de servicios */
.services-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  padding-right: 1.5%;
}

/* Item de servicios */
.service-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2%;
}

.service-item h3 {
  font-size: 100%; /* Cambiado a porcentaje */
  font-weight: 600;
  color: #79ab92;
  margin: 0;
}

.service-item p {
  font-size: 100%; /* Cambiado a porcentaje */
  color: #666666;
  margin-top: 0.5%;
  padding-left: 4.2%;
}

.service-item div {
  display: flex;
  align-items: center;
}

.logo-mobile-container {
  display: none;
}

/* Contenedor del logotipo */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo {
  max-width: 20%;
  height: auto;
  filter: none;
  opacity: 1;
  z-index: 3;
}

.logo-header {
  max-width: 5%; /* Cambiado a porcentaje */
  height: auto;
  margin-right: 2%;
  vertical-align: middle;
  cursor: pointer;
}

/* Pie de página */
.footer {
  background-color: #666666;
  color: white;
  text-align: center;
  padding: 2% 0;
}

.footer img {
  filter: grayscale(100%);
  max-width: 10%;
  margin: 2% auto;
}

.footer a {
  color: #79ab92;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Grid de servicios */
.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2%;
  margin-top: 2%;
}

/* Acordeón */
.accordion {
  display: flex;
  justify-content: center; /* Centra el grid en el contenedor */
  flex-direction: column; /* Coloca los acordeones en una columna */
  align-items: center; /* Centra los elementos hijos horizontalmente */
}

.accordion-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Crea un grid de 3 columnas */
  gap: 10px; /* Espacio entre los acordeones */
  max-width: 1200px; /* Ancho máximo del grid */
  width: 100%; /* Permite que el grid ocupe el 100% del contenedor */
}

.accordion-item {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px; /* Añade bordes redondeados */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Añade sombra */
  transition: transform 0.2s; /* Efecto de transformación */
  max-height: 225px;
  max-width: 97%;
}

.accordion-item:hover {
  transform: scale(1.02); /* Efecto de escalado al pasar el ratón */
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-description {
  margin: 10px 0;
}

.accordion-details {
  display: none;
  margin: 10px 0;
}

.accordion-details.open {
  display: block;
}

.accordion-item.open{
  max-height: 100%;
}

.accordion-item.open .accordion-details {
  display: block;
}

/* Estilos Responsivos */
@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Reduce el número de columnas a 2 en pantallas medianas */
  }

  .logo {
    max-width: 25%; /* Logo más grande en pantallas medianas */
  }

  .logo-header {
    display: none; /* Oculta el logotipo en pantallas medianas */
  }

  .footer img {
    max-width: 15%; /* Imagen del pie de página un poco más grande */
  }

  .logo-mobile-container {
    margin-bottom: 20px;
  }


  .logo-container {
    display: none;
  }

  .logo-mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr; /* Una sola columna en pantallas pequeñas */
    display: block;
  }

  .service-item p {
    font-size: 100%; /* Reducir tamaño de fuente para mejorar la legibilidad */
  }

  .logo {
    max-width: 50%; /* Logo aún más grande en pantallas pequeñas */
  }

  .footer img {
    max-width: 20%; /* Imagen del pie de página más grande */
  }

  .accordion-item {
    padding: 3%; /* Más espacio para el acordeón en pantallas pequeñas */
  }
  .logo-mobile-container {
    margin-bottom: 20px;
  }


  .logo-container {
    display: none;
  }

  .logo-mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 5% 0; /* Aumenta el padding en el pie de página para más espacio */
  }

  .footer img {
    max-width: 30%; /* Imagen más grande en pantallas muy pequeñas */
  }

  .service-item h3 {
    font-size: 100%;
  }

  .service-item p {
    font-size: 100%;
  }

  .accordion-item {
    padding: 4%; /* Más espacio para el acordeón en pantallas pequeñas */
  }
}

/* Sección ¿Por qué elegirnos? */
.porque-elegirnos-section {
  background-color: #79ab92;
  padding: 4% 0;
}

.porque-elegirnos-section .section-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.porque-elegirnos-section .section-title,
.porque-elegirnos-section p {
  color: white;
}

.porque-elegirnos-section image {
  width: 50%;
  filter: grayscale(100%);
  margin-left: 2%;
}

.porque-elegirnos-section .text-content {
  width: 50%;
}

/* Sección de contacto */
.contact-container,
.porque-elegirnos-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2%;
  margin-top: 5%;
  margin-bottom: 10%;
}

.map-container,
.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
}

.map-container iframe {
  flex: 1;
  min-height: 45%;
}

/* Formulario de contacto */
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin: 10px 0;
}

.form-group {
  margin-bottom: 2%;
}

.form-group label {
  display: block;
  margin-bottom: 1%;
  color: #79ab92;
  font-weight: bold;
}

/* Estilos para inputs y textarea con borde gris claro */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1.5%;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 100%;
  font-family: "Montserrat", sans-serif;
  outline: none;
  box-shadow: none;
  background-color: white;
  color: #666;
}

/* Cambia el color del borde al enfocar el campo */
.form-group input:focus,
.form-group textarea:focus {
  border-color: #ccc;
  outline: none;
  box-shadow: none;
}

/* Botón de envío */
#recaptcha-container {
  margin-bottom: 12px;
}

/* Ajustes para el botón durante el estado de carga */
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Espacio entre el spinner y el texto */
  padding: 1.5% 2%;
  background-color: #79ab92;
  color: white;
  border: none;
  border-radius: 0.5%;
  font-size: 100%;
  cursor: pointer;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.3s ease;
}

.submit-btn:disabled {
  background-color: #6a917f; /* Color del botón cuando está deshabilitado */
  cursor: not-allowed; /* Cambia el cursor para indicar que está deshabilitado */
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3); /* Color de fondo del spinner */
  border-left-color: white; /* Color del borde que gira */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#message {
  max-width: 100%;
  min-height: 100px;
  max-height: 220px;
  resize: vertical;
}

.submit-btn:hover {
  background-color: #666666;
}

/* Estilos Responsive */
@media (max-width: 1200px) {
  .section-image {
    display: none !important; /* Oculta la imagen en pantallas medianas */
  }

  /* Ajustes para el título "¿Por qué elegirnos?" */
  .porque-elegirnos-section .section-title {
    font-size: 100%;
    margin-bottom: 3%; /* Agrega margen inferior para separar del contenido */
  }

  .porque-elegirnos-section .text-content {
    margin-bottom: 5%; /* Asegura que haya espacio entre el título y los acordeones */
  }

  .accordion-item {
    margin-top: 5%; /* Añade espacio arriba de los acordeones */
  }
}

@media (max-width: 768px) {
  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .menu.open {
    display: flex; /* Mostrar menú cuando se abre */
  }

  /* Mostrar el ícono de hamburguesa en móviles */
  .hamburger-icon {
    display: block;
  }

  .navbar a {
    display: block;
    padding: 10px;
    text-align: center;
  }

  .section-image {
    display: none !important; /* Oculta la imagen en pantallas pequeñas */
  }

  .porque-elegirnos-section .section-title {
    font-size: 100%;
    margin-bottom: 4%; /* Más espacio entre el título y los acordeones */
  }

  .porque-elegirnos-section .text-content {
    width: 100%; /* El texto ocupa todo el ancho en pantallas pequeñas */
    text-align: center;
    margin-bottom: 6%; /* Aumenta el espaciado para pantallas pequeñas */
  }

  .accordion-item {
    margin-top: 6%; /* Añade más espacio antes de los acordeones */
  }

  .contact-container {
    flex-direction: column; /* Los contenedores se alinean en columna en pantallas pequeñas */
  }

  .map-container iframe {
    min-height: 30%; /* Menor altura en pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  .section-image {
    display: none !important; /* Oculta la imagen en pantallas muy pequeñas */
  }

  .porque-elegirnos-section .section-title {
    font-size: 100%;
    margin-bottom: 6%; /* Mayor separación en pantallas muy pequeñas */
  }

  .porque-elegirnos-section .text-content {
    width: 100%;
    text-align: center;
  }

  .accordion-item {
    margin-top: 6%;
    max-height: 250px;
  }

  .contact-form {
    margin-bottom: 5%; /* Más espacio inferior para pantallas pequeñas */
  }

  .submit-btn {
    padding: 2% 2.5%; /* Más espacio en el botón en pantallas pequeñas */
  }
}  

/* Novedades */
.swiper-slide .novedad-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.novedad-text {
  flex: 2; /* El texto ocupará más espacio */
  padding-right: 20px;
  max-width: 60%; /* Limita el ancho del bloque de texto */
}

.novedad-image {
  flex: 1; /* La imagen ocupará menos espacio */
  text-align: right;
}

.novedad-image img {
  max-width: 100%; /* Asegura que la imagen no sobrepase su contenedor */
  height: auto;
  width: 500px; /* Controla el tamaño de la imagen */
}

.swipe-text {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 10;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
  .novedad-content {
    flex-direction: column; /* Apila el contenido en pantallas pequeñas */
    text-align: center;
  }

  .novedad-text {
    max-width: 100%; /* Permite que el texto ocupe todo el ancho en pantallas pequeñas */
    padding-right: 0;
  }

  .novedad-image {
    margin-top: 20px;
    text-align: center;
  }

  .novedad-image img {
    width: 100%; /* Asegura que la imagen ocupe el ancho completo en pantallas pequeñas */
  }
}
